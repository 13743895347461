import { Component, OnDestroy } from '@angular/core';
import { RequestService } from '../shared/services/request.service';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { HolidaysService } from '../shared/services/holidays.service';
import { IRequest } from '../shared/models/request.model';
import { IHoliday } from '../shared/models/holiday.model';
import {
  catchError,
  filter,
  forkJoin,
  Observable,
  Subject,
  takeUntil,
  tap,
  throwError,
} from 'rxjs';
import { DialogComponent } from '../shared/dialogs/dialog/dialog.component';
import { RequestStatusEnum } from '../shared/enums/request-status.enum';
import { AlertService, AlertType } from '../shared/services/alert.service';
import { LoginService } from '../shared/services/login.service';
import { TranslateService } from '@ngx-translate/core';
import { ImageListDialogComponent } from '../shared/dialogs/image-list-dialog/image-list-dialog.component';
import { switchMap } from 'rxjs/operators';
import { DIALOG_BUTTONS } from '../shared/constants';

@Component({
  selector: 'app-request-details',
  templateUrl: './request-details.component.html',
  styleUrls: ['./request-details.component.scss'],
})
export class RequestDetailsComponent implements OnDestroy {
  readonly MORNING: number = 1;
  readonly AFTERNOON: number = 2;
  readonly WEEK_DAYS: string[] = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  public currentRequest: IRequest;

  public allHolidays: Array<IHoliday>;
  public days: Array<Date> = [];
  public patternMorning: Array<string> = [];
  public patternAfternoon: Array<string> = [];
  public isLoading: boolean = true;
  private destroy$ = new Subject<void>();

  public fromDateWeekDay: string;
  public toDateWeekDay: string;
  readonly buttons = DIALOG_BUTTONS;

  constructor(
    private requestService: RequestService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private holidayService: HolidaysService,
    private alertService: AlertService,
    private loginService: LoginService,
    public translate: TranslateService,
  ) {
    this.isWorkingDay = this.isWorkingDay.bind(this);

    this.route.params
      .pipe(
        takeUntil(this.destroy$),
        switchMap((param) => {
          return forkJoin({
            holidays: this.holidayService.getAllHolidays(),
            request: this.requestService.getRequestById(param.id),
          });
        }),
      )
      .subscribe((data) => {
        this.allHolidays = data.holidays;
        this.currentRequest = data.request;
        this.prepareRequestData();
        this.isLoading = false;
      });
  }

  private prepareRequestData(): void {
    const day: Date = new Date(this.currentRequest.fromDate);
    const endDay: Date = new Date(this.currentRequest.toDate);

    this.fromDateWeekDay = '(' + this.WEEK_DAYS[day.getDay() - 1] + ')';
    this.toDateWeekDay = '(' + this.WEEK_DAYS[endDay.getDay() - 1] + ')';

    while (day.getTime() <= new Date(this.currentRequest.toDate).getTime()) {
      if (this.isWorkingDay(day)) {
        this.days.push(new Date(day));
      }
      day.setDate(day.getDate() + 1);
    }
    [...this.currentRequest.pattern].forEach((ch) => {
      if (this.is(this.MORNING, ch)) {
        this.patternMorning.push('X');
      } else {
        this.patternMorning.push('');
      }
      if (this.is(this.AFTERNOON, ch)) {
        this.patternAfternoon.push('X');
      } else {
        this.patternAfternoon.push('');
      }
    });
  }

  private is(mask: number, ch: string): boolean {
    // eslint-disable-next-line no-bitwise
    return (Number(ch) & mask) > 0;
  }

  private isWorkingDay(date: Date): boolean {
    const isWeekend: boolean = date.getDay() === 0 || date.getDay() === 6;
    const isHoliday: boolean = this.allHolidays.some(
      (h) => new Date(h.date).toLocaleDateString() === date.toLocaleDateString(),
    );
    return !isWeekend && !isHoliday;
  }

  public openDetailsPicDialog(): void {
    const fileList: string[] = this.currentRequest.filename.split(';');
    this.dialog.open(ImageListDialogComponent, {
      data: {
        userId: this.currentRequest.requester.id,
        requestId: this.currentRequest.id,
        fileList,
        readOnly: true,
      },
    });
  }

  public openDialogApprove(): void {
    if (this.currentRequest.status === 'APROVED') {
      return;
    }

    this.dialog
      .open(DialogComponent, {
        data: {
          title: this.translate.instant('REQUEST_DETAILS.APPROVE_CONFIRMATION').toString(),
          description: this.translate
            .instant('REQUEST_DETAILS.APPROVE_CONFIRMATION_DESCRIPTION')
            .toString(),
          sharedButtonClass: this.buttons.warningButton,
          sharedButtonText: this.translate.instant('REQUEST_DETAILS.APPROVE').toString(),
        },
      })
      .afterClosed()
      .pipe(
        takeUntil(this.destroy$),
        filter((result) => result),
        tap(() => {
          this.currentRequest.status = RequestStatusEnum.APPROVED;
        }),
        switchMap(() => this.actionRequest(this.currentRequest.id, true)),
      )
      .subscribe();
  }

  public openDialogDecline(): void {
    if (this.currentRequest.status === 'DECLINED') {
      return;
    }

    this.dialog
      .open(DialogComponent, {
        data: {
          title: this.translate.instant('REQUEST_DETAILS.DECLINE_CONFIRMATION').toString(),
          description: this.translate
            .instant('REQUEST_DETAILS.DECLINE_CONFIRMATION_DESCRIPTION')
            .toString(),
          sharedButtonClass: this.buttons.deleteButton,
          sharedButtonText: this.translate.instant('REQUEST_DETAILS.DECLINE').toString(),
        },
      })
      .afterClosed()
      .pipe(
        takeUntil(this.destroy$),
        filter((result) => result),
        tap(() => {
          this.currentRequest.status = RequestStatusEnum.DECLINED;
        }),
        switchMap(() => this.actionRequest(this.currentRequest.id, false)),
      )
      .subscribe();
  }

  private actionRequest(id: number, approveAction: boolean): Observable<IRequest> {
    return this.requestService.actionRequestWithoutHash(id, approveAction).pipe(
      takeUntil(this.destroy$),
      tap((req: IRequest) => {
        this.currentRequest = req;
        this.prepareRequestData();
        this.alertService.showAlert(
          approveAction
            ? this.translate.instant('REQUEST_DETAILS.REQUEST_APPROVED').toString()
            : this.translate.instant('REQUEST_DETAILS.REQUEST_DECLINED').toString(),
          AlertType.success,
        );
      }),
      catchError((error) => {
        this.alertService.showAlert(error.error, AlertType.error);
        return throwError(error);
      }),
    );
  }

  public applicableForModification(): boolean {
    const dateFrom = new Date(this.currentRequest?.fromDate).setHours(0);
    const dateNow = new Date(Date.now()).setHours(0);

    return (
      this.loginService.getUserId() === this.currentRequest.approver.id &&
      (this.currentRequest.status === 'PENDING' || dateFrom > dateNow)
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

<mat-form-field
  appearance="fill"
  color="accent"
  (click)="picker1.open()"
>
  <mat-label>{{ label }}</mat-label>
  <input
    matInput
    [matDatepicker]="picker1"
    [min]="minDate"
    [max]="maxDate"
    [(value)]="value"
  />
  <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
  <mat-datepicker
    #picker1
    startView="multi-year"
    color="primary"
    (monthSelected)="onMonthSelected($event, picker1)"
  ></mat-datepicker>
</mat-form-field>

<mat-spinner *ngIf="isLoading"></mat-spinner>

<app-submit-report />

<app-emp-report-export
  [enabledControls]="{ month: true, project: true }"
  [title]="'ADMIN.CLIENT_HOURS.TITLE' | translate"
  (emitSubmit)="onSubmit($event)"
></app-emp-report-export>

<h2 class="section-title">
  {{ 'ADMIN.CLIENT_HOURS.SUB_TITLE' | translate }}
</h2>

<app-calendar [isCalendarHome]="false" />

<mat-spinner *ngIf="isLoading"></mat-spinner>

<div [hidden]="isLoading">
  <h2 class="section-title">
    {{ 'REQUEST_DETAILS.REQUEST_DETAILS' | translate }}
  </h2>

  <div class="wrapper">
    <div>
      <label>{{ 'REQUEST_DETAILS.REQUESTER' | translate }}</label>
      <p>
        {{ currentRequest?.requester.firstNameEn + ' ' + currentRequest?.requester.lastNameEn }}
      </p>
    </div>
    <div>
      <label>{{ 'REQUEST_DETAILS.REQUESTER_EMAIL' | translate }}</label>
      <p>{{ currentRequest?.requester.email }}</p>
    </div>
    <div>
      <label>{{ 'REQUEST_DETAILS.APPROVER' | translate }}</label>
      <p>
        {{ currentRequest?.approver.firstNameEn + ' ' + currentRequest?.approver.lastNameEn }}
      </p>
    </div>
    <div>
      <label>{{ 'REQUEST_DETAILS.APPROVER_EMAIL' | translate }}</label>
      <p>{{ currentRequest?.approver.email }}</p>
    </div>
    <div>
      <label>{{ 'REQUEST_DETAILS.FROM' | translate }}</label>
      <p>{{ currentRequest?.fromDate }} {{ fromDateWeekDay }}</p>
    </div>
    <div>
      <label>{{ 'REQUEST_DETAILS.TO' | translate }}</label>
      <p>{{ currentRequest?.toDate }} {{ toDateWeekDay }}</p>
    </div>
    <div>
      <label>{{ 'REQUEST_DETAILS.AMOUNT_OF_DAYS' | translate }}</label>
      <p>{{ currentRequest?.days }}</p>
    </div>
    <div>
      <label>{{ 'REQUEST_DETAILS.VACATION_TYPE' | translate }}</label>
      <p>
        {{ currentRequest?.vacationType }}
        <span
          *ngIf="
            currentRequest?.vacationType === 'Sick leave' ||
            currentRequest?.vacationType === 'Other paid leave'
          "
          >( {{ currentRequest?.subType }} )</span
        >
      </p>
    </div>
    <div>
      <label>{{ 'REQUEST_DETAILS.ADDITIONAL_INFORMATION' | translate }}</label>
      <p>{{ currentRequest?.additionalInfo }}</p>
    </div>
    <div></div>
    <div
      *ngIf="
        (currentRequest?.vacationType === 'Sick leave' ||
          currentRequest?.vacationType === 'Other paid leave') &&
        currentRequest.filename
      "
    >
      <label>{{ 'REQUEST_DETAILS.ATTACHED_MEDIA' | translate }}</label
      ><br />
      <button mat-icon-button (click)="openDetailsPicDialog()">
        <mat-icon>list_alt</mat-icon>
      </button>
    </div>
    <div>
      <label>{{ 'REQUEST_DETAILS.POSTED_ON' | translate }}</label>
      <p>{{ currentRequest?.createdOn }}</p>
    </div>
    <div>
      <label>{{ 'REQUEST_DETAILS.STATUS' | translate }}</label>
      <p>{{ currentRequest?.status }}</p>
    </div>
    <div>
      <label>{{ 'REQUEST_DETAILS.RESOLVED_ON' | translate }}</label>
      <p *ngIf="currentRequest?.status !== 'PENDING'">
        {{ currentRequest?.resolvedOn }}
      </p>
      <p *ngIf="currentRequest?.status === 'PENDING'">
        {{ 'REQUEST_DETAILS.MUST_BE_RESOLVED' | translate }}
      </p>
    </div>
    <div *ngIf="currentRequest && applicableForModification()">
      <button
        mat-flat-button
        color="primary"
        [disabled]="currentRequest.status === 'APPROVED'"
        (click)="openDialogApprove()"
      >
        {{ 'REQUEST_DETAILS.APPROVE' | translate }}</button
      >&nbsp;
      <button
        mat-flat-button
        color="warn"
        [disabled]="currentRequest.status === 'DECLINED'"
        (click)="openDialogDecline()"
      >
        {{ 'REQUEST_DETAILS.DECLINE' | translate }}
      </button>
    </div>
  </div>
</div>
